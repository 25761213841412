.windowLeft {
  position: absolute;
  transform-origin: left;
  z-index: 25;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.455, -0.02, 0.805, 1.65);
}

.windowTop4 {
  composes: windowLeft;
  top: 4%;
}

.windowTop9 {
  composes: windowLeft;
  top: 9%;
}

.windowTop15 {
  composes: windowLeft;
  top: 15%;
}

.windowTop14 {
  composes: windowLeft;
  top: 14%;
}

.windowTop16 {
  composes: windowLeft;
  top: 16%;
}

.windowTop17 {
  composes: windowLeft;
  top: 17%;
}

.windowTop19 {
  composes: windowLeft;
  top: 19%;
}

.windowTop20 {
  composes: windowLeft;
  top: 20%;
}

.windowTop21 {
  composes: windowLeft;
  top: 21%;
}

.windowTop36 {
  composes: windowLeft;
  top: 36%;
}

.windowTop33 {
  composes: windowLeft;
  top: 33%;
}

.width50Top8Left {
  composes: windowLeft;
  top: 8%;
}

.width50Top24Left {
  composes: windowLeft;
  width: 50%;
  top: 24%;
}

.width55Left {
  composes: windowLeft;
  width: 55%;
}

.width55Top20Left {
  composes: windowLeft;
  width: 55%;
  top: 20%;
}

.width60Left {
  composes: windowLeft;
  width: 60%;
}

.width60Top36Left {
  composes: windowLeft;
  width: 60%;
  top: 36%;
}

.width60Top16Left {
  composes: windowLeft;
  width: 60%;
  top: 16%;
}

.width70Left {
  composes: windowLeft;
  width: 70%;
}

.width80Left {
  composes: windowLeft;
  width: 80%;
}

.width90Top8Left {
  composes: windowLeft;
  width: 90%;
  top: 8%;
}

.insideImg {
  position: absolute;
  z-index: 23;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 0.3s ease 0.2s;
  width: 100%;
}

.windowBack {
  position: absolute;
  z-index: 20;
  height: 100%;
  width: 100%;
}

.windowHover {
  position: absolute;
  z-index: 30;
  height: 100%;
  width: 100%;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.455, -0.02, 0.805, 1.65);
  display: flex;
  justify-content: center;
  align-items: center;
}

.windowHover:hover + .windowLeft {
  top: -60%;
}

.windowHover:hover .insideImg {
  opacity: 1;
}

.title {
  font-size: 14px;
  text-transform: capitalize;
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link :hover {
  color: inherit;
  text-decoration: none;
}
