.rhap_container {
  background-color: #1e1e1e;
}

.rhap_progress-bar-show-download {
  background-image: url('/img/png/progress_bar_loaded.png') !important;
  background-size: cover;
}

.rhap_download-progress {
  background-image: url('/img/png/progress_bar_loaded.png') !important;
  background-size: cover;
  background-color: transparent;
}

.rhap_progress-filled {
  background-image: url('/img/png/progress_bar_filled.png') !important;
  background-size: cover;
}

.rhap_volume-bar {
  background-image: url('/img/png/progress_bar_filled.png') !important;
  background-size: cover;
}

.rhap_progress-indicator {
  background-image: url('/img/svg/control_dot_time_and_volume.svg') !important;
}

.rhap_volume-indicator {
  background-image: url('/img/svg/control_dot_time_and_volume.svg') !important;
}

.rhap_main-controls-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button:focus-visible {
  outline: none;
  border: none;
}

.rhap_repeat-button svg path {
  fill: #2998ff !important;
}

.rhap_volume-button svg path {
  fill: #2998ff !important;
}

.rhap_time {
  color: #fff;
}
