.windowLeft {
  position: absolute;
  transform-origin: left;
  z-index: 25;
  transform: rotateY(0);
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.455, -0.02, 0.805, 1.65);
}

.windowRight {
  position: absolute;
  transform-origin: 100% 50%;
  z-index: 25;
  transform: rotateY(0);
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.455, -0.02, 0.805, 1.65);
}

.insideImg {
  position: absolute;
  z-index: 23;
  opacity: 0;
  width: 100%;
  transform: scale(0.6);
  transition: opacity 0.3s ease 0.2s;
}

.windowBack {
  position: absolute;
  z-index: 20;
  height: 100%;
  width: 100%;
}

.windowHover {
  position: absolute;
  z-index: 30;
  height: 100%;
  width: 100%;
  transition-property: all;
  transition-duration: 0.55s;
  transition-timing-function: cubic-bezier(0.455, -0.02, 0.805, 1.65);
  display: flex;
  justify-content: center;
  align-items: center;
}

.windowHover:hover + .windowLeft {
  transform: rotateY(180deg);
}

.windowHover:hover ~ .windowRight {
  transform: rotateY(180deg);
}

.windowHover:hover .insideImg {
  opacity: 1;
}

.darkTitle {
  font-size: 14px;
  text-transform: capitalize;
  text-align: center;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
}

.lightTitle {
  composes: darkTitle;
}

@media (min-width: 768px) {
  .lightTitle {
    color: #fff;
  }

  .lightTitle:hover {
    color: #fff;
  }
}

.link {
  color: inherit;
  text-decoration: none;
}

.link :hover {
  color: inherit;
  text-decoration: none;
}
