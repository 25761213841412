* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Innerspace';
  src: local('Innerspace'),
    url('/fonts/Innerspace/InnerspaceRegular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url('/fonts/Open Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url('/fonts/Open Sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url('/fonts/Open Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Balsamiq Sans';
  src: local('Balsamiq Sans'),
    url('/fonts/Balsamiq Sans/BalsamiqSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Balsamiq Sans';
  src: local('Balsamiq Sans'),
    url('/fonts/Balsamiq Sans/BalsamiqSans-Regular.ttf') format('truetype');
  font-weight: regular;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('/fonts/LatoRegular.ttf') format('truetype');
  font-weight: normal;
}

.partners h2 {
  padding-top: 20px;
  font-family: 'Innerspace', sans-serif;
  word-spacing: 8px;
  font-size: 1.5rem;
  line-height: 33px;
  text-transform: uppercase;
  color: #fff;
}

.partners h2:not(:first-of-type) {
  margin-top: 40px;
}

.partners p {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-transform: none;
  white-space: pre-line;
  margin-top: 14px;
}

.partners li {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-transform: none;
  white-space: pre-line;
  margin-top: 8px;
}

.partners__text--margin {
  margin-bottom: 14px;
}

.partners strong {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-transform: uppercase;
}

.partners a {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-transform: none;
  color: #00d2df;
  text-decoration: none;
  cursor: pointer;
}

.partners a:hover {
  color: #00d2df;
  text-decoration: none;
}
